import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomainService } from 'src/app/services/domain.service';

@Component({
  selector: 'app-remove-alert',
  templateUrl: './remove-alert.component.html',
  styleUrls: ['./remove-alert.component.scss']
})
export class RemoveAlertComponent implements OnInit {

  @Input() text: string = '';
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal, private domainService:DomainService) { }

  ngOnInit(): void {
  }

  passBack(): void {
    this.passEntry.emit('REMOVE');
    this.activeModal.close('REMOVE');
  }

  close(): void {
    this.activeModal.close();
  }
  public getTranslatedText = (text: string | any) => this.domainService.getTranslatedText(text);

}
