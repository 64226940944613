<div class="remove-alert-modal">
    <div class="modal-body container-fluid">
        <div class="item-body">
            <h6 class="title-txt">{{getTranslatedText(text)}}</h6>
        </div>

        <div class="modal-footer d-flex align-items-center">
            <button type="submit" class="alert-btn alert-btn__cancel" (click)="close()">{{getTranslatedText("Cancel")}}</button>
            <button type="submit" class="alert-btn alert-btn__yes" (click)="passBack()">{{getTranslatedText("Yes")}}</button>
        </div>
    </div>
</div>