import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MonthlyFrequencyPriceDetails, MonthlyPackage, MonthlyPackageFrequency } from '../models/subCategory.model';
import { Rating } from '../models/rating.model';
import * as englishJson from '../../assets/i18n/en.json';
import * as arabicJson from '../../assets/i18n/ar.json';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  private crntLang: string = 'en';
  private translationCache = new Map<string, string>();
  private numberPattern = /^\d+/;

  // Assume these are initialized with your JSON data
  private englishJson: { [key: string]: any } = englishJson;
  private arabicJson: { [key: string]: any } = arabicJson;
  private flatEnglishJson: { [key: string]: string } = {};


  constructor(private afs: AngularFirestore,
    private dataService: DataService
  ) {
    this.flattenObject(this.englishJson, '', this.flatEnglishJson);

    this.dataService.crntLang.subscribe(crntLang => {
      this.crntLang = crntLang;
      this.translationCache.clear();
    });
  }

  public async getTermsAndCondition() {
    const res = await this.afs.collection('systemconfig').doc('termsConditions').get().toPromise();
    return res.data();
  }
  public getDayLabel(dateStr: string): string {
    const today = new Date().toISOString().slice(0, 10);
    const givenDate = new Date(dateStr).toISOString().slice(0, 10);
    if (givenDate === today) {
      return 'Today';
    } else if (new Date(givenDate).getTime() === new Date(today).getTime() + 1000 * 60 * 60 * 24) {
      return 'Tomorrow';
    } else {
      return '';
    }
  }

  public calculateEndTime(startTime: string, minutes?: number): string {
    if (!startTime) return "";
    let [startHours, startMinutes] = startTime.split(':');
    let meridiem = startMinutes?.split(' ')[1];
    const hours = parseInt(startHours, 10);
    const minutesToAdd = minutes ? parseInt(minutes?.toString(), 10) : 0;
    let totalMinutes = parseInt(startMinutes, 10) + minutesToAdd;
    let newHours = hours + Math.floor(totalMinutes / 60);
    totalMinutes %= 60;
    if (newHours > 12) {
      newHours -= 12;
      meridiem === 'AM' ? (meridiem = 'PM') : (meridiem = 'AM');
    }
    const formattedStartMinutes = startMinutes.padStart(2, '0');
    const formattedEndMinutes = totalMinutes.toString().padStart(2, '0');
    const period = meridiem == 'AM' && hours < 12 ? 'Morning' : (hours < 17 ? 'Afternoon' : 'Evening');
    return `${period} ${hours}:${formattedStartMinutes} ${minutes ? `- ${newHours}:${formattedEndMinutes} ${meridiem}` : ''} `;
  }

  public getFrequencyItemPrice(pkg?: MonthlyPackage, frequency?: MonthlyPackageFrequency, priceDetails?: MonthlyFrequencyPriceDetails, itemPrice: number = 0, addOnSum: number = 0, codFee: number = 0, tipFee: number = 0): number {
    if (!pkg || !frequency) return 0;
    const unitPrice = itemPrice;
    const addOnPrice = addOnSum;
    const cod = codFee;
    const tip = tipFee;
    const count = pkg.weekCount ?? 0;
    const visitsPerWeek = frequency.visitsPerWeek ?? 0;
    const res = (unitPrice + addOnPrice + cod + tip) * visitsPerWeek * count;
    const total = res - this.getDiscountAmount(priceDetails, res);
    return total;
  }

  public getDiscountAmount(priceDetails?: MonthlyFrequencyPriceDetails, price: number = 0): number {
    if (!priceDetails?.discount || !priceDetails.discountText || !priceDetails.discountType) {
      return 0;
    }
    if (priceDetails.discountType === 'percentage') {
      return price * (priceDetails.discount / 100);
    } else if (priceDetails.discountType === 'fixed') {
      return priceDetails.discount;
    }
    return 0;
  }

  public getFrequencyPriceDetails(priceDetails: any, itemId: string, freqId: string): any {
    if (priceDetails && itemId && freqId) {
      return priceDetails[itemId][freqId];
    }
    return null;
  }

  private addZeros(value: number) {
    return value.toLocaleString("en", { useGrouping: false, minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  public getTimeLabel(time: string): string {
    if (!time) return "";
    const hour = parseInt(time.split(':')[0]);
    if (hour >= 5 && hour < 12) {
      return "Morning";
    } else if (hour >= 12 && hour < 18) {
      return "Afternoon";
    } else {
      return "Evening";
    }
  }

  public addMinutesToTime(time: string, minutesToAdd: number): string {
    if (!time || !minutesToAdd) return "";
    const [hourStr, minuteStr] = time.split(':');
    let hour = parseInt(hourStr);
    let minute = parseInt(minuteStr.substring(0, 2));
    const period = time.slice(-2);
    if (period === "PM" && hour !== 12) {
      hour += 12;
    } else if (period === "AM" && hour === 12) {
      hour = 0;
    }
    const totalMinutes = hour * 60 + minute + minutesToAdd;
    const newHour = Math.floor(totalMinutes / 60) % 24;
    const newMinute = totalMinutes % 60;
    const newPeriod = newHour >= 12 ? "PM" : "AM";
    const formattedHour = newHour === 0 ? 12 : (newHour > 12 ? newHour - 12 : newHour);
    const formattedMinute = newMinute < 10 ? `0${newMinute}` : `${newMinute}`;
    return `${formattedHour}:${formattedMinute}${newPeriod}`;
  }

  public isMatDiscountEnabled(item: any, itemMap: any) {
    return item?.cutOfPrice?.discount != null && (itemMap?.minutes >= 60 && itemMap?.minutes < 180)
  }

  public getStaffRatingType(): Rating[] {
    return [
      { key: 'unlike', label: 'Bad', rating: 3, icon: 'assets/images/icons/bad.png', rated: false },
      { key: 'like', label: 'Okay', rating: 4, icon: 'assets/images/icons/good.png', rated: false },
      { key: 'better', label: 'Very Good', rating: 5, icon: 'assets/images/icons/very-good.png', rated: false }
    ]
  }

  private flattenObject(obj: any, prefix: string, result: any) {
    for (const k in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, k)) {
        const key = prefix ? `${prefix}.${k}` : k;
        if (typeof obj[k] === 'object' && obj[k] !== null) {
          this.flattenObject(obj[k], key, result);
        } else {
          result[key] = obj[k];
        }
      }
    }
  }

  getTranslatedText(text: string): string {
    if (!text) return '';

    // Use cached result if available
    if (this.translationCache.has(text)) {
      return this.translationCache.get(text)!;
    }

    if (this.crntLang === 'en') {
      this.translationCache.set(text, text);
      return text;
    }

    const match = text.match(this.numberPattern);
    const numberPart = match ? match[0] : '';
    const textWithoutNumber = match ? text.replace(this.numberPattern, '').trim() : text.trim();

    // Find key in flattened English JSON
    const key = Object.keys(this.flatEnglishJson).find(k =>
      this.flatEnglishJson[k].toLowerCase() === textWithoutNumber.toLowerCase()
    );

    if (key && this.arabicJson) {
      let arabicValue = this.arabicJson;
      const pathParts = key.split('.');

      let retrievedArabicValue: string | undefined;
      for (let part of pathParts) {
        if (arabicValue && typeof arabicValue === 'object' && Object.prototype.hasOwnProperty.call(arabicValue, part)) {
          arabicValue = arabicValue[part];
        } else {
          retrievedArabicValue = undefined;
          break;
        }
      }
      retrievedArabicValue = typeof arabicValue === 'string' ? arabicValue : undefined;

      if (retrievedArabicValue) {
        // Reattach the number part to the translated text
        const result = numberPart ? `${numberPart} ${retrievedArabicValue}` : retrievedArabicValue;
        this.translationCache.set(text, result);
        return result;
      } else {
        console.error(`Translation for "${text}" not found or is nested further than expected.`);
      }
    }

    // If no translation is found, return the original text
    this.translationCache.set(text, text);
    return text;
  }



  getTranslatedAmount(amount: string): string {
    // Normalize the input to remove extra spaces around the currency and amount
    const normalizedAmount = amount.replace(/\s+/g, ' ').trim();

    // Regular expression to match the optional negative sign, currency code, and amount
    const match = normalizedAmount.match(/^(-\s*)?([A-Za-z]+)\s*(\d+(\.\d{1,2})?)$/);

    // If it matches the format with a currency and amount
    if (match) {
      const sign = match[1] ? match[1].trim() : ''; // Capture and trim the negative sign if present
      const currency = match[2]; // The currency code (e.g., AED)
      const number = match[3]; // The numeric part of the amount (e.g., 15.00)

      // Translate the currency code
      const translatedCurrency = this.getTranslatedText(currency);

      // Reconstruct the translated amount
      return `${sign}${translatedCurrency} ${number}`;
    }

    // Check if the input contains only alphabetic characters (e.g., a currency code without amount)
    if (/^[A-Za-z]+$/.test(normalizedAmount)) {
      // Translate the currency code if it's purely alphabetic
      const translatedCurrency = this.getTranslatedText(normalizedAmount);
      return translatedCurrency;
    }

    // If the input does not match any expected format, return it as is
    return amount;
  }

  public getJobItemLabel(input: string) {
    // First, try to match the case with parentheses (e.g., "Lab Test at Home - Individual (1 Person)")
    const matchWithParentheses = input.match(/^(.*?)\s-\s(.*?)\s\((\d+)\s(.*?)\)$/);

    // If the input matches the pattern with parentheses, return the formatted label
    if (matchWithParentheses) {
      const [, subCategory, primaryItem, count, secondaryText] = matchWithParentheses;
      return `${this.getTranslatedText(subCategory)} - ${this.getTranslatedText(primaryItem)} (${count} ${this.getTranslatedText(secondaryText)})`;
    }

    // Handle the case with format "Apartment (2 BHK)"
    const matchDetailsFormat = input.match(/^(.*?)\s\((.*?)\)$/);

    if (matchDetailsFormat) {
      const [, category, details] = matchDetailsFormat;
      return `${this.getTranslatedText(category)} (${this.getTranslatedText(details)})`;
    }

    // Handle the new format "1 Electrician - Inspection / Call out"
    const matchServiceFormat = input.match(/^(\d+)\s(.*?)\s-\s(.*?)\s\/\s(.*?)$/);
    if (matchServiceFormat) {
      const [, count, serviceType, action1, action2] = matchServiceFormat;
      return `${count} ${this.getTranslatedText(serviceType)} - ${this.getTranslatedText(action1)} / ${this.getTranslatedText(action2)}`;
    }

    // Otherwise, handle the case without parentheses (e.g., "Standard - Individual - 1 Person")
    const parts = input.split(' - ');
    if (parts.length === 3) {
      const [subCategory, primaryItem, countPerson] = parts;
      const [count, personText] = countPerson.split(' ');
      return `${this.getTranslatedText(subCategory)} - ${this.getTranslatedText(primaryItem)} (${count} ${this.getTranslatedText(personText)})`;
    }

    // If no pattern matches, return the input unchanged
    return input;
  }

  public getTranslatedValue(value: string) {
    if (value?.startsWith('AED')) {
      return this.getTranslatedAmount(value);
    } else {
      return this.getTranslatedText(value);
    }
  }


}