import { PlatformLocation } from '@angular/common';
import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Address } from 'src/app/models/address.model';
import { Category } from 'src/app/models/category.model';
import { SubCategory } from 'src/app/models/subCategory.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { DomainService } from 'src/app/services/domain.service';
import { FcmService } from 'src/app/services/fcm.service';
import { HomeService } from 'src/app/services/home.service';
import { LocaldataService } from 'src/app/services/localdata.service';
import { MytaskService } from 'src/app/services/mytask.service';
import { PerformanceService } from 'src/app/services/performance.service';
import { QuickBookingService } from 'src/app/services/quick-booking.service';
import { RouteService } from 'src/app/services/route.service';
import { getFormatedAddress } from 'src/app/shared/logics/addressFormat';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {

  isLoggedin: boolean = false;
  showAddress: boolean = true;
  subscription: Subscription | undefined;
  profileNameSubscription: Subscription | undefined;
  profileImageSubscription: Subscription | undefined;
  crntUserSub: Subscription | undefined;
  availableDiscountSub?: Subscription;
  addressIdSubscription?: Subscription;
  guestAddressSubscription?: Subscription;
  userData?: User;
  localUid: string = '';
  currentUser?: User;
  profileImageUrl: string = 'assets/images/user.svg';
  profileName: string = 'My Profile';
  webProfileName: string = 'My Profile';
  subCategories: SubCategory[] = [];
  menuItemList: Category[] = [];
  mainList: { main: Category; sub: SubCategory[]; }[] = [];
  menuList: { main: Category; sub: SubCategory[]; }[] = [];
  isReferralActive: boolean = false;
  refActivateData: any;
  selectedAddress?: Address;
  defaultAddressId?: string;

  public innerWidth: any;
  public crntLang: string = '';
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private data: DataService,
    private quickBookingService: QuickBookingService,
    private myTaskService: MytaskService,
    private homeService: HomeService,
    private platformLocation: PlatformLocation,
    private dataService: DataService,
    private localdataService: LocaldataService,
    private performanceService: PerformanceService,
    private modalService: NgbModal,
    private routeService: RouteService,
    private fcmService: FcmService,
    private domainService: DomainService,
    private translate: TranslateService) {
    const crntLang = this.data.getCrntLang();
    this.switchLanguage(crntLang);
    translate.addLangs(['en', 'ar']);
    translate.setDefaultLang('en');

    // Use the browser's default language if available
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');
    this.homeService.getMenuItemsSubscription().subscribe(data => {
      this.updateMenuData(data);
    });
    this.homeService.getAllSubCatSubscription().subscribe(data => {
      this.subCategories = data;
    });
    this.crntUserSub = this.data.crntUser.subscribe(data => this.updateUserInfo(data));
    this.platformLocation.onPopState(() => {
      if (this.modalService.hasOpenModals()) {
        this.modalService.dismissAll();
        this.quickBookingService.setModalOpenState(false);
      }
    });
    this.quickBookingService.getUserInfoSub().subscribe(data => this.updateUserInfo(data));
    this.checkAddressVisibility();
  }

  async ngOnInit(): Promise<void> {
    this.innerWidth = window.innerWidth;
    this.homeService.getMenuItemsInit();
    this.localUid = localStorage.getItem('uid') || '';
    this.isLoggedin = this.authService.isLoggedIn;
    this.subscription = this.data.crntIsLoggedIn.subscribe(isLoggedIn => this.setLoggedInState(isLoggedIn));
    this.profileNameSubscription = this.data.crntProfileName.subscribe(profileName => this.setProfileName(profileName));
    this.profileImageSubscription = this.data.crntProfileImage.subscribe(imageUrl => this.profileImageUrl = imageUrl);
    this.browserNavigationCheck();
    this.availableDiscountSub = this.dataService.crntAvailableDiscounts.subscribe(data => this.setRefActivateData(data));
    if (this.isLoggedin) {
      this.userData = await this.authService.getUserData(this.localUid);
      this.authService.monitorAuthState();
    }
    this.currentUser = this.userData;
    this.profileImageUrl = this.currentUser?.photoUrl ? this.currentUser.photoUrl : '/assets/images/user.svg';
    this.setProfileName(this.currentUser?.displayName);
    this.addressIdSubscription = this.dataService.crntDefaultAddressId.subscribe(id => this.setDefaultAddress(id));
    this.guestAddressSubscription = this.dataService.crntGuestAddress.subscribe(address => this.setGuestAddress(address));
  }

  ngAfterViewInit(): void {
    let didShowConfirmLocation = sessionStorage.getItem("didShowConfirmLocation");
    if (didShowConfirmLocation === "TRUE") return;
    setTimeout(() => {
      this.confirmLocation();
      sessionStorage.setItem("didShowConfirmLocation", "TRUE");
    }, 6000);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.profileNameSubscription?.unsubscribe();
    this.profileImageSubscription?.unsubscribe();
    this.crntUserSub?.unsubscribe();
    this.availableDiscountSub?.unsubscribe();
    this.addressIdSubscription?.unsubscribe();
    this.guestAddressSubscription?.unsubscribe();
  }

  switchLanguage(lang: string) {
    const languages: any = { "en": "English", "ar": "Arabic" };
    this.crntLang = languages[lang];
    this.translate.use(lang);
    // document.body.setAttribute('dir', lang === 'ar' ? 'rtl' : 'ltr');
    this.data.changeLang(lang);
  }

  updateUserInfo(data: User) {
    this.currentUser = data;
    this.profileImageUrl = this.currentUser?.photoUrl ? this.currentUser.photoUrl : '/assets/images/user.svg';
    this.setProfileName(this.currentUser?.displayName);
    this.setDefaultAddress(this.dataService.getDefaultAddressId());
  }

  setLoggedInState(isLoggedIn: boolean) {
    if (isLoggedIn === this.isLoggedin) return;
    this.isLoggedin = isLoggedIn;
    this.fcmService.init();
  }

  loginLogout() {
    this.isLoggedin = this.authService.isLoggedIn;
    if (this.isLoggedin) {
      localStorage.removeItem('uid');
      this.data.clearSession();
      this.data.clearLocal();
      this.quickBookingService.clearUser();
      this.isLoggedin = false;
      this.data.changeIsLoggedIn(false);
      this.authService.clearData();
      this.router.navigate(['/home']);
    } else {
      this.authService.openModal(true);
    }
  }

  navigateToProfile() {
    this.isLoggedin = this.authService.isLoggedIn;
    if (this.isLoggedin) {
      this.router.navigate(['/profile']);
    } else {
      this.authService.openModal(true);
    }
  }

  navigateHome() {
    this.router.navigate(['/dubai']);
  }

  updateMenuData(data: Category[]) {
    this.menuItemList = data;
    if (this.subCategories) {
      var mainCategoryDocIds: string[] = [];
      this.menuItemList.forEach((e) => {
        mainCategoryDocIds.push(e.docId);
      })
      this.menuItemList.forEach((menuItem) => {
        var subList: SubCategory[] = [];
        this.subCategories.forEach((e) => {
          if (e?.categoryIds?.includes(menuItem.docId)) {
            subList.push(e);
          }
        })
        this.menuList.push({ main: menuItem, sub: subList });
      })
    }
    this.mainList = this.menuList.slice(0, 6);
  }

  navigateToMyTasks() {
    this.myTaskService.changeActiveTaskTab(0);
    this.router.navigate(['/my-task']);
  }

  activateVoucher() {
    if (!this.isLoggedin) {
      sessionStorage.setItem('isSessionActive', 'TRUE');
      this.authService.openModal(true);
      return;
    }
    const res = { discounts: [], status: '', message: '' };
    this.quickBookingService.openAvailablePromoModal(res, false, true);
  }

  setProfileName(displayName?: string) {
    this.webProfileName = displayName ? 'Hi ' + displayName : 'My Profile';
    this.profileName = displayName ? displayName : 'My Profile';
  }

  setRefActivateData(data: any) {
    const _data = data?.res ?? data;
    this.refActivateData = _data?.refActivateDetails;
    this.isReferralActive = _data?.activateReferral ? true : false;
  }

  activateReferral() {
    const isLoggedIn = this.dataService.getIsLoggedIn();
    if (!isLoggedIn) {
      this.authService.openModal(true);
      return;
    }
    this.quickBookingService.openActivateReferralModal(this.refActivateData);
  }

  browserNavigationCheck() {
    const navType = this.performanceService.getNavigationType();
    if (navType === 'reload') {
      this.dataService.changeCrntPageReload(true);
      return;
    }
    this.dataService.changeCrntPageReload(false);
  }

  /* setDefaultAddress(id?: string) {
    this.defaultAddressId = id;
    const _addresses = this.currentUser?.addresses ?? [];
    if (_addresses?.length > 0) {
      this.selectedAddress = _addresses.find(i => i.id === id) as Address;
      if (!this.selectedAddress) {
        this.selectedAddress = _addresses[0];
      }
    }
    else {
      if (this.selectedAddress?.id !== 'GUEST01')
        this.selectedAddress = this.localdataService.getDefaultAddress();
    }
  } */

  setDefaultAddress(id?: string) {
    this.defaultAddressId = id;
    const addresses = this.currentUser?.addresses ?? [];
    if (addresses.length > 0) {
      this.selectedAddress = addresses.find(address => address.id === id) || addresses[0];
    } else if (this.selectedAddress?.id !== 'GUEST01') {
      this.selectedAddress = this.localdataService.getDefaultAddress();
    }
    this.setCrntAddress(this.selectedAddress);
  }

  confirmLocation() {
    const urls: string[] = ['/bookings/payment', '/dubai/maid-service/Q012', '/dubai', '/'];
    if (!this.isLoggedin && this.selectedAddress?.id !== 'GUEST01' && !urls?.includes(this.router.url.split('?')[0])) {
      this.quickBookingService.openAddressModal(undefined, undefined, undefined, undefined, undefined, true);
    }
  }

  changeLocation() {
    if (!this.isLoggedin) {
      this.quickBookingService.openAddressModal(undefined, undefined, undefined, undefined, undefined, true);
      this.dataService.changeDefaultAddressId('');
    }
    if (this.isLoggedin) this.dataService.changeGuestAddress(false);
    if (!this.routeService.isQuickBookingURL()) {
      this.dataService.changeServicePolygons(false);
      this.dataService.chnageDisabledPolygons(false);
      let crntInfo = this.dataService.getMaidServiceInfo();
      let updatedInfo = { ...crntInfo, ...{ servicePolygons: [] } };
      this.dataService.changeMaidServiceInfo(updatedInfo);
    }
    if (this.selectedAddress && this.selectedAddress?.id !== 'DFLT0001' && this.selectedAddress?.id !== 'GUEST01')
      this.router.navigate(['/addresses/select']);
    else if (this.selectedAddress?.id === 'GUEST01') this.changeGuestAddress();
    else if (!this.isLoggedin) this.confirmLocation();
    else this.quickBookingService.openAddressModal();
  }

  getLocationStr(address?: Address) {
    if (address?.id === 'DFLT0001')
      return address?.location ?? 'Dubai, UAE';
    if (address?.id === 'GUEST01')
      return address?.location ?? 'Dubai, UAE';
    return address ? getFormatedAddress(address) : undefined;
  }

  setGuestAddress(address?: Address) {
    if (address?.id === 'GUEST01' && !this.defaultAddressId) {
      this.selectedAddress = address;
      this.setCrntAddress(this.selectedAddress);
    }
  }

  changeGuestAddress() {
    if (this.selectedAddress) {
      // this.quickBookingService.openAddressModal(undefined, undefined, this.selectedAddress, undefined, undefined, true);
      this.quickBookingService.openAddressModal(undefined, undefined, undefined, undefined, undefined, true);
    }
    else this.confirmLocation();
  }

  setCrntAddress(address?: Address) {
    if (address && address?.id) {
      this.dataService.changeCrntAddress(address);
    }
  }

  public checkAddressVisibility() {
    const includes: string[] = ['/dubai', '/home'];
    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationStart || event instanceof NavigationEnd) {
          this.showAddress = includes.includes(event.url != '/' ? event.url : this.router.url);
        }
      }
    });
  }

  public getTranslatedText = (text: string) => this.domainService.getTranslatedText(text);

}
