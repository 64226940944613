import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomainService } from 'src/app/services/domain.service';

@Component({
  selector: 'app-tnc',
  templateUrl: './tnc.component.html',
  styleUrls: ['./tnc.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TncComponent implements OnInit {

  @Input() title?: string;
  @Input() tnc: any;
  @Input() content: string = "";
  @Input() type: number = 0; // 0: list, 1: paragraphs

  constructor(public activeModal: NgbActiveModal, private domainService: DomainService) { }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.close();
  }
  public getTranslatedText = (text: string | any) => this.domainService.getTranslatedText(text);


}
