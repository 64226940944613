<header class="header header--fixed" id="header">

    <div class="container header--top">
        <!-- <span class="header--hamburger">
            <span class="icon-bar icon-bar-a"></span>
            <span class="icon-bar icon-bar-b"></span>
            <span class="icon-bar icon-bar-c"></span>
        </span> -->
        <span class="empty-span"></span>
        <a (click)="navigateHome()" title="yellowzap"
            [ngClass]="{'header--logo-sm': innerWidth <= 320}"
            class="header--logo">
            <figure>
                <img role="img" aria-label="icon" alt title
                    src="assets/images/logo-yellowzap.webp">
            </figure>
            <p>YellowZap<span>{{getTranslatedText("Get all your tasks done…. !!!")}}</span></p>
        </a>
        <div *ngIf="showAddress"
            class="location-bar d-none d-md-flex align-items-center justify-content-between">
            <div class="left-sec d-flex">
                <img src="assets/images/icons/loc-pin.webp" alt>
                <div>
                    <h6 class="main-loc">{{selectedAddress?.landmarkKey ??
                        'Motor City'}}</h6>
                    <h6 class="sub-loc">{{getLocationStr(selectedAddress) ??
                        selectedAddress?.location ?? 'Dubai, UAE'}}
                    </h6>
                </div>
            </div>
            <a (click)="changeLocation()">{{getTranslatedText("Change")}}</a>
        </div>
        <ul>
            <li class="location_select web-only">
                <a class="dropdown-toggle" href="#" role="button"
                    id="dropdownMenuLocation" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <figure><img
                            [src]="crntLang==='English'?'assets/images/icons/en.png':'assets/images/icons/dubai.png'"
                            alt></figure>
                    {{getTranslatedText(crntLang)}}
                </a>
                <ul class="dropdown-menu dropdown-menu-end"
                    aria-labelledby="dropdownMenuLocation">
                    <li>
                        <a class="dropdown-item"
                            (click)="switchLanguage('en')"><img
                                src="assets/images/icons/en.png"
                                alt>English</a>
                        <a class="dropdown-item"
                            (click)="switchLanguage('ar')"><img
                                src="assets/images/icons/dubai.png"
                                alt>{{getTranslatedText("Arabic")}}</a>
                    </li>
                </ul>
            </li>
            <li *ngIf="isLoggedin" class="d-none d-xl-block">
                <a (click)="navigateToMyTasks()">
                    <span class="my-task my-task--web"></span>
                    <span class="my-task my-task--mobile"></span>
                    {{getTranslatedText("My Tasks")}}
                </a>
            </li>
            <li *ngIf="!isLoggedin">
                <a (click)="loginLogout()" class="d-none d-md-block">
                    <span class="login login--web"></span>
                    <span class="login login--mobile"></span>
                    {{getTranslatedText("Login")}} /
                    {{getTranslatedText("Sign Up")}}
                </a>
                <a (click)="loginLogout()" class=" d-md-none">
                    <span class="login login--web"></span>
                    <span class="login login--mobile"></span>
                    {{getTranslatedText("Login")}} / <br>
                    {{getTranslatedText("Sign Up")}}
                </a>
            </li>
            <li class="profile_name" *ngIf="isLoggedin">
                <a class="dropdown-toggle ps-1" role="button" href="#"
                    id="dropdownMenuProfile"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <figure class="profile-fig">
                        <img src="{{profileImageUrl}}" alt>
                    </figure>
                    <!-- {{profileName}} -->
                    <p class="my-auto ms-2 d-xl-none">{{profileName}}</p>
                    <p
                        class="my-auto ms-2 d-none d-xl-block">{{webProfileName}}</p>
                    <img class="chev-dwn d-xl-none"
                        src="/assets/images/icons/dw-ar-sm-bk.svg">
                </a>
                <ul class="dropdown-menu dropdown-menu-end"
                    aria-labelledby="dropdownMenuProfile">
                    <li><a class="dropdown-item"
                            (click)="navigateToMyTasks()">{{getTranslatedText("My Tasks")}}</a></li>
                    <li><a class="dropdown-item"
                            (click)="navigateToProfile()">{{getTranslatedText("My Profile")}}</a></li>
                    <li><a class="dropdown-item"
                            routerLink="/addresses/view">{{getTranslatedText("My Addresses")}}</a></li>
                    <li>
                        <hr class="dropdown-divider">
                    </li>
                    <li><a class="dropdown-item"
                            href="https://blog.yellowzap.com/" target="_blank"
                            rel="noopener noreferrer">{{getTranslatedText("Blog")}}</a></li>
                    <li><a class="dropdown-item"
                            (click)="activateVoucher()">{{getTranslatedText('Activate Super Saver')}}</a></li>
                    <li *ngIf="isReferralActive"><a class="dropdown-item"
                            (click)="activateReferral()">{{getTranslatedText("Activate Referral Code")}}</a></li>
                    <li><a class="dropdown-item highlight"
                            routerLink="/referral">{{getTranslatedText("Refer Friends & Get AED 15 Off")}}</a>
                    </li>
                    <li><a class="dropdown-item"
                            routerLink="/contact-us">{{getTranslatedText("Help")}}</a></li>
                    <li><a class="dropdown-item" routerLink="/tnc">{{getTranslatedText("Terms of Service")}}</a></li>
                    <li>
                        <hr class="dropdown-divider">
                    </li>
                    <li><a (click)="loginLogout()" class="dropdown-item">{{getTranslatedText("Log Out")}}</a></li>
                </ul>
            </li>
            <!-- <li>
              <a class="yc-icon-btn" *ngIf="isLoggedin" routerLink="/my-task">My Tasks</a>
          </li> -->
            <!-- <li>
                <div class="chat-box">
                    <p>Chats</p>
                    <span>16</span>
                </div>
            </li> -->
        </ul>
    </div>
    <!-- <nav class="header--nav navbar">
        <div class="container header--overflow">
            <ul class="header--bottom-nav">
                <li>
                    <a style="cursor: pointer;">ALL SERVICES</a>
                    <ul class="dropdown-menu" style="max-height: 400px; overflow-y: auto;">
                        <li *ngFor="let item of menuList">
                            <a style="cursor: pointer;">{{item?.main?.categoryName}}</a>
                        </li>
                    </ul>
                    <span class="drop-down"></span>
                </li>
                <li *ngFor="let item of mainList">
                    <a style="cursor: pointer;">{{ item?.main?.categoryName}}</a>
                    <ul *ngIf="item?.sub?.length" class="dropdown-menu" style="max-height: 300px; overflow-y: auto;">
                        <li *ngFor="let subItem of item?.sub">
                            <a *ngIf="subItem" style="cursor: pointer;">{{ subItem?.subCategoryName }}</a>
                        </li>
                    </ul>
                    <ul *ngIf="!item?.sub?.length" class="dropdown-menu multi-level" role="menu"
                        aria-labelledby="dropdownMenu">
                        <li>
                            <a>No Groups</a>
                        </li>
                    </ul>
                </li>
                <li class="mobile_only">
                    <select name="" id="">
                        <option value="Dubai">Dubai</option>
                    </select>
                </li>
                <li class="mobile_only" *ngIf="!isLoggedin">
                    <a (click)="loginLogout()" class="d-flex flex-row align-items-center">
                        <figure>
                            <img src="assets/images/user.svg" alt="">
                        </figure>
                        <p class="m-0">Log in / sign up</p>
                    </a>
                </li>
                <li class="mobile_only" *ngIf="isLoggedin" data-bs-toggle="collapse">
                    <a class="d-flex flex-row align-items-center" (click)="navigateToProfile()">
                        <figure>
                            <img src="{{profileImageUrl}}" alt="">
                        </figure>
                        <p class="m-0">{{profileName}}</p>
                    </a>
                    <ul class="dropdown-menu" style="max-height: 400px; overflow-y: auto;">
                        <li><a (click)="navigateToProfile()">My Profile</a></li>
                        <li><a routerLink="/addresses/view">My Addresses</a></li>
                        <li><a>Help</a></li>
                        <li><a routerLink="/tnc">Terms of Service</a></li>
                        <li><a (click)="loginLogout()">Log out</a></li>
                    </ul>
                    <span class="drop-down"></span>
                </li>
            </ul>
        </div>
    </nav> -->
    <div *ngIf="showAddress"
        class="d-flex d-md-none align-items-center justify-content-between location-bar location-bar__mobile">
        <div class="left-sec d-flex">
            <img src="assets/images/icons/loc-pin.webp" alt>
            <div>
                <h6 class="main-loc">{{selectedAddress?.landmarkKey ??
                    'Motor City'}}</h6>
                <h6 class="sub-loc">{{getLocationStr(selectedAddress) ??
                    selectedAddress?.location ?? 'Dubai, UAE'}}
                </h6>
            </div>
        </div>
        <a (click)="changeLocation()">{{getTranslatedText("Change")}}</a>
    </div>
</header>