import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DomainService } from 'src/app/services/domain.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  promoLink = false;
  downloadLink = false;
  aboutUsLink = false;

  constructor(private router: Router, private domainService: DomainService) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => {
      if (this.router.url.replace(/^\/+/, '') === 'home') {
        this.promoLink = false;
        this.downloadLink = false;
        this.aboutUsLink = false;
      } else if (this.router.url.replace(/^\/+/, '') === 'home/promotions') {
        this.promoLink = true;
        this.downloadLink = false;
        this.aboutUsLink = false;
      } else if (this.router.url.replace(/^\/+/, '') === 'home/downloads') {
        this.promoLink = false;
        this.downloadLink = true;
        this.aboutUsLink = false;
      } else if (this.router.url.replace(/^\/+/, '') === 'about-us' || this.router.url.replace(/^\/+/, '') === 'contact-us') {
        this.aboutUsLink = true;
        this.promoLink = false;
        this.downloadLink = false;
      } else {
        this.promoLink = false;
        this.downloadLink = false;
        this.aboutUsLink = false;
      }
    });
  }

  ngOnInit(): void {
  }

  onPromotionClick() {
    this.router.navigate(['/home', 'promotions']);
  }

  onDownloadClick() {
    this.router.navigate(['/home', 'downloads']);
  }

  onAboutUsClick() {
    this.router.navigate(['/about-us']);
  }

  public getTranslatedText = (text: string) => this.domainService.getTranslatedText(text);


}
