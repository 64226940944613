import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GoogleMap } from '@angular/google-maps';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Address } from 'src/app/models/address.model';
import { Booking } from 'src/app/models/booking.model';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { EventLogService } from 'src/app/services/event-log.service';
import { GeolocationService } from 'src/app/services/geolocation.service';
// import { getPosition, isDubaiCountry } from 'src/app/services/locationService';
import { ProfileService } from 'src/app/services/profile.service';
import { QuickBookingService } from 'src/app/services/quick-booking.service';
import { SkipLocationComponent } from './skip-location/skip-location.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { SpinnerOverlayService } from 'src/app/services/spinner-overlay.service';
declare var $: any;
declare global {
  interface Window { gMapDidInit: boolean; }
};
window.initMapCallback = function () { };

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss'],
  // encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddAddressComponent implements OnInit, AfterViewInit, OnDestroy {

  localUid!: string;
  landmarkKeys: string[] = ['Home', 'Office', 'Work'];

  data: {
    location?: string,
    flatNo?: string,
    landmark?: string,
    landmarkKey?: string
  }[] = [];

  selectedKey: any = 'Home';

  defaultLatLng = {
    lat: 25.042853,
    lng: 55.241818
  }

  selectedLatLng = {
    lat: 25.042853,
    lng: 55.241819
    // lat: 25.203868,
    // lng: 55.268214
  }

  initialMapCoordinates: any = this.selectedLatLng

  marker = this.initialMapCoordinates;

  dubai_bounds = {
    north: 25.5269,
    east: 56.205,
    south: 24.6212,
    west: 54.714
  }

  mapConfigs = {
    center: this.initialMapCoordinates,
    disableDefaultUI: true,
    zoomControl: true,
    draggable: true,
    zoom: 12,
    marker: this.initialMapCoordinates,
    restriction: {
      latLngBounds: this.dubai_bounds,
      strictBounds: true
    },
    language: 'en'
  }

  googleMapMarker: any

  searchOptions = {
    bounds: this.dubai_bounds,
    strictBounds: true,
    componentRestrictions: { country: "ae" },
    language: 'en'
  }

  disableSubmit: boolean = false;
  booking!: Booking;
  isMapLoaded: boolean = false;
  isSearchable: boolean = false;
  selectedLocation: string = '';

  addressList: Address[] = [];

  addressComponents: any[] = [];
  geoPoint!: firebase.default.firestore.GeoPoint;
  placeId: string = '';
  name: string = '';
  vicinity: string = '';
  servicePolygonsSubscription: Subscription | undefined;
  servicePolygons: any[] = [];
  disabledPolygons: any[] = [];
  mapElement!: google.maps.Map<HTMLElement>;

  @Input() public addressListData!: Address[];
  @Input() public currentAddress!: Address;
  @Input() public isTempAddress!: boolean;
  @Input() public tempUid: any;
  @Input() public isConfirmationOnly?: boolean;

  @ViewChild('mapSearchField') searchField!: ElementRef;
  @ViewChild(GoogleMap) map!: GoogleMap;

  addAddressForm = this.fb.group({
    location: [this.selectedLocation, [Validators.required]],
    flatNo: ['', [Validators.required, Validators.minLength(1)]],
    // landmark: [''],//[Validators.minLength(3)]
    flatName: ['', [Validators.required, Validators.minLength(1)]],
    // landmarkKey: ['', [Validators.required, Validators.minLength(1)]],
    locality: [''],
    blockNo: ['']
  });

  isLoggedIn: boolean = false;
  addButtonText: string = 'Confirm Address';
  latLngStr: string = '';
  showAdditionalDetails: boolean = false;
  nearLandmark: string = '';
  isLocating: boolean = false;
  isScriptLoaded: boolean = false;
  showMapScreen: boolean = true;
  getGeoStatus: any;
  crntGeo: any;
  crntAddressComponents: any;
  crntLocStr: string = '';
  user!: User;
  profileForm!: FormGroup;
  titles: string[] = ['Mr', 'Miss', 'Mrs', 'Ms'];
  title!: string;
  submitted: boolean = false;
  hasDisplayName: boolean = false;
  modalTitle: string = "Your Address";
  isIdleState: boolean = true;
  subCategoryData: any;
  isAutocomplete: boolean = false;
  placeAddressData?: {
    lat: number,
    lng: number,
    addressComponents: []
  }

  constructor(
    private quickbookingServcice: QuickBookingService,
    private fb: FormBuilder,
    private profileService: ProfileService,
    private toastr: ToastrService,
    private quickBookingService: QuickBookingService,
    // private spinnerService: SpinnerOverlayService,
    private apiService: ApiService,
    private authService: AuthService,
    private dataService: DataService,
    private geolocationService: GeolocationService,
    private eventLog: EventLogService,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.initGoogleMaps();
    this.servicePolygonsSubscription = this.dataService.crntServicePolygons.subscribe(data => this.setServicePolygons(data));
  }

  ngOnInit(): void {

    this.isLoggedIn = this.authService.isLoggedIn;
    // this.openFullScreen();
    this.modalTitle = this.isConfirmationOnly ? "Confirm Service Location" : "Your Address";
    this.setLandmarkKeys();
    this.isLocating = true;
    this.setCategoryData();
    this.localUid = localStorage.getItem('uid') || '';
    if (this.localUid) {
      this.getUserInfo(this.localUid);
    }
    this.disableSubmit = false;
    this.addressList = this.addressListData;
    if (this.currentAddress) {
      this.crntGeo = { lat: this.currentAddress?.currentGeo?.latitude, lng: this.currentAddress?.currentGeo?.longitude }
      this.selectedLocation = this.currentAddress.location ?? ''
      this.addButtonText = 'Save Changes'
      this.addAddressForm.setValue({
        location: this.currentAddress.location ?? '',
        flatNo: this.currentAddress.flatNo ?? '',
        // landmark: this.currentAddress.landmark ?? '',
        flatName: this.currentAddress.flatName ?? '',
        locality: this.currentAddress.localityArea ?? '',
        blockNo: this.currentAddress.blockNo ?? ''
      });
      this.selectedKey = this.currentAddress.landmarkKey ? this.currentAddress.landmarkKey : 'Home';
      if (this.crntGeo.lat && this.crntGeo.lng)
        this.isLocating = false;
    } else {
      // this.setGuestAddressInfo();
    }
    this.setDisabledPolygons();
  }

  ngAfterViewInit(): void {
    if (this.crntGeo?.lat && this.crntGeo?.lng) {
      this.initialMapCoordinates = {
        lat: this.crntGeo?.lat,
        lng: this.crntGeo?.lng
      }
      this.mapConfigs.center = this.initialMapCoordinates;
      this.mapConfigs.marker = this.initialMapCoordinates;
      this.initMap(this.crntGeo?.lat, this.crntGeo?.lng);
      this.initGeocodeData(this.crntGeo?.lat, this.crntGeo?.lng);
      this.isLocating = false;
      return;
    }
    // this.geolocationService.getPosition().then(pos => {
    //   // console.log(`Positon: ${pos?.lat},${pos?.lng}`)
    //   this.initialMapCoordinates = {
    //     lat: pos.lat,
    //     lng: pos.lng
    //   }
    //   this.mapConfigs.center = this.initialMapCoordinates
    //   this.mapConfigs.marker = this.initialMapCoordinates
    //   this.initMap(pos.lat, pos.lng)
    //   this.initGeocodeData(pos.lat, pos.lng)
    //   this.isLocating = false;
    // }).catch((e) => {
    //   console.log(`Positon Error: ${e}`)
    //   this.initMap()
    //   this.isLocating = false;
    // })

    this.initMap(this.selectedLatLng.lat, this.selectedLatLng.lng);
    this.initGeocodeData(this.selectedLatLng.lat, this.selectedLatLng.lng);
    this.isLocating = false;
  }

  ngOnDestroy(): void {
    this.servicePolygonsSubscription?.unsubscribe();
  }

  async getUserInfo(uid: string) {
    this.user = await this.profileService.getUser(uid);
    this.title = this.user?.title || 'Mr';
    this.hasDisplayName = this.user?.displayName ? true : false;

    const _address = this.currentAddress ?? this.dataService.getGuestAddress();
    if (_address.id === 'GUEST01' && !this.isConfirmationOnly) {
      this.skipMapScreen(_address);
    }

    if (this.hasDisplayName) return;
    this.profileForm = this.fb.group({
      title: ['Mr', Validators.required],
      displayName: ['', Validators.required]
    });
  }

  initGoogleMaps() {
    if (window.gMapDidInit) {
      this.isScriptLoaded = true;
      this.initMap();
      return;
    } else {
      const mapsScript = document.createElement('script')
      mapsScript.async = true;
      mapsScript.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCz2OEjrEDXhGtWBv-tXb4q0JCub24XuWs&callback=initMapCallback&libraries=places&language=en';
      document.head.appendChild(mapsScript);
      window.gMapDidInit = true;
      mapsScript.onload = () => {
        this.isScriptLoaded = true;
        this.initMap(this.crntGeo?.lat, this.crntGeo?.lng);
      };
    }
  }

  initMap(lat?: number, lng?: number) {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (!this.isScriptLoaded) return;
    const map = (window.gMapDidInit && document?.getElementById("gmap")) ? new google.maps.Map(document?.getElementById("gmap") as HTMLElement, this.mapConfigs) : null;
    if (!map) return;

    this.mapElement = map;
    const autocomplete = new google.maps.places.Autocomplete(this.searchField?.nativeElement, this.searchOptions);
    // map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.searchField.nativeElement);
    const icon = {
      url: '/assets/images/icons/home.webp',
      scaledSize: new google.maps.Size(50, 50),
    };
    this.googleMapMarker = new google.maps.Marker({
      map,
      position: new google.maps.LatLng(lat ??this.defaultLatLng.lat, lng ?? this.defaultLatLng.lng),
      anchorPoint: new google.maps.Point(0, -29),
      draggable: true,
      icon: icon
    });
    autocomplete.addListener('place_changed', () => {
      this.googleMapMarker.setVisible(false);
      const place = autocomplete.getPlace()
      this.isAutocomplete = true;
      if (!place?.geometry || !place?.geometry?.location) {
        return
      }
      this.isSearchable = false;

      // if (place?.geometry?.location?.lat() && place?.geometry?.location?.lng()) {
      //   this.selectedLatLng = {
      //     lat: place.geometry.location.lat(),
      //     lng: place.geometry.location.lng()
      //   }
      // }

      // if (!isDubaiCountry(place?.geometry?.location?.lat(), place?.geometry?.location?.lng())) {
      if (!this.geolocationService.isValidLocation(place?.geometry?.location?.lat(), place?.geometry?.location?.lng(), this.servicePolygons)) {
        // console.log('Sorry we are currently offering our service only in Dubai')
        this.addAddressForm.get('location')?.reset()
        if (this.googleMapMarker && this.selectedLatLng?.lat) {
          map.setCenter(new google.maps.LatLng(this.selectedLatLng.lat, this.selectedLatLng.lng));
          this.googleMapMarker.setPosition(new google.maps.LatLng(this.selectedLatLng.lat, this.selectedLatLng.lng))
          this.googleMapMarker.setVisible(true);
        }
        // this.toastr.clear()
        this.toastr.error(
          this.getServiceZoneErrorTxt(), 'No Serving Area',
          { positionClass: 'toast-bottom-center',toastClass:'address-toastr ngx-toastr' },
        )
        return
      }

      this.marker = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }
      this.selectedLocation = place?.formatted_address as string;
      this.addAddressForm.get('location')?.setValue(place?.formatted_address);

      this.addressComponents = place?.address_components as any;
      this.addAddressForm.get('locality')?.setValue(this.getLocality(this.addressComponents));
      this.selectedLatLng = {
        lat: place?.geometry?.location?.lat(),
        lng: place.geometry?.location?.lng()
      }
      this.placeAddressData = {
        lat: place?.geometry?.location?.lat(),
        lng: place.geometry?.location?.lng(),
        addressComponents: place?.address_components as any
      };
      this.geoPoint = new firebase.default.firestore.GeoPoint(place?.geometry?.location?.lat(), place.geometry?.location?.lng());
      this.placeId = place?.place_id ?? '';
      this.name = place?.name ?? '';
      this.vicinity = place?.vicinity ?? '';

      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(17);
      }
      this.googleMapMarker.setPosition(place.geometry.location);
      this.googleMapMarker.setVisible(true);
    });

    google.maps.event.addListener(this.googleMapMarker, 'dragend', (evt) => {

      // var lat = evt?.latLng?.lat()?.toFixed(6) ?? '-';
      // var lng = evt?.latLng?.lng()?.toFixed(6) ?? '-';
      // this.latLngStr = lat + ', ' + lng;
      // this.geoPoint = new firebase.default.firestore.GeoPoint(lat, lng);
      // this.getGeocodeData();
      this.initGeocodeData(evt?.latLng?.lat(), evt?.latLng?.lng(), undefined, true);
      this.isSearchable = false;
    })

    map.addListener("idle", () => {
      this.isIdleState = true;
      this.isSearchable = false;
    });

    map.addListener("center_changed", () => {

      this.isIdleState = false;
      this.isSearchable = false;
      let center = new google.maps.LatLng(this.selectedLatLng?.lat, this.selectedLatLng?.lng);
      if (this.isAutocomplete) {
        center = new google.maps.LatLng(this.selectedLatLng?.lat, this.selectedLatLng?.lng);
        this.googleMapMarker.setPosition(center);
        this.googleMapMarker.setVisible(true);
        this.isAutocomplete = false;
      } else {
        center = map.getCenter();
        this.googleMapMarker.setPosition(center);
        this.googleMapMarker.setVisible(true);
      }
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        if (!this.isLocating && this.isIdleState){
          this.initGeocodeData(center?.lat(), center?.lng(), true);
        }
      }, 400);
    });
    // this.drawZonePolygons(this.servicePolygons, map, false);
    // this.drawZonePolygons(this.disabledPolygons, map, true);
  }

  closeModal() {
    this.quickbookingServcice.closeModal();
    // this.closeFullScreen();
  }

  closeSearchTab(e: any) {
    if (e?.target?.value) {
      this.closeSearchModal();
    }
  }

  async addAddress() {
    if (this.geoPoint && this.geoPoint.latitude && this.geoPoint.longitude) {
      // const getGeoStatus = await this._getGeocodeData(this.geoPoint?.latitude, this.geoPoint?.longitude);
      if (!this.getGeoStatus) {
        this.toastr.clear()
        this.toastr.error(
          'Please select your location on map!', 'Something went wrong!',
          { positionClass: 'toast-bottom-center',toastClass:'address-toastr ngx-toastr' },
        )
        return;
      }
    }

    if (!this.selectedLocation || !this.addAddressForm?.value?.location) {
      this.toastr.clear()
      this.toastr.error(
        'Please select your location on map!', 'Something went wrong!',
        { positionClass: 'toast-bottom-center',toastClass:'address-toastr ngx-toastr' },
      )
      return;
    }

    this.updateDisplayName();
    if (!this.hasDisplayName && this.profileForm?.invalid) return;

    if (this.addAddressForm.invalid || !this.selectedKey) {
      this.toastr.clear();
      this.toastr.error(
        'Please complete all required fields!', 'Something went wrong!',
        { positionClass: 'toast-bottom-center',toastClass:'address-toastr ngx-toastr' },
      );
      return;
    }
    this.disableSubmit = true;
    // this.spinnerService.openLoadingModal();
    if (this.authService.isLoggedIn && !this.isTempAddress) {
      this.eventLog.addedAddress(this.addAddressForm?.value?.location);
      await this.profileService.addAddress(
        this.localUid,
        {
          'flatNo': this.addAddressForm.value.flatNo,
          'flatName': this.addAddressForm.value.flatName,
          'id': Date.now().toString(),
          'isWebUser': true,
          'landmark': this.nearLandmark,
          'landmarkKey': this.selectedKey ?? '',
          'location': this.addAddressForm.value.location,
          'localityArea': this.addAddressForm.value.locality ?? '',
          'blockNo': this.addAddressForm.value.blockNo ?? null,
          'createdAt': firebase.default.firestore.Timestamp.now(),
          'currentGeo': this.geoPoint ? this.geoPoint : null,
          ...(this.selectedLocation && {
            'placeDetails': {
              // 'description': this.addressComponents ?? null,
              'geo': this.geoPoint?.latitude ? {
                'lat': this.geoPoint?.latitude,
                'lng': this.geoPoint?.longitude,
              } : null,
              'name': this.name ?? null,
              'addressComponents': this.addressComponents ?? null,
              'formattedAddress': this.selectedLocation,
              'placeId': this.placeId ?? null
            }
          })
        });
    } else {
      var tempAddress = {
        'flatNo': this.addAddressForm.value.flatNo,
        'flatName': this.addAddressForm.value.flatName,
        'id': Date.now().toString(),
        'isWebUser': true,
        'landmark': this.nearLandmark,
        'landmarkKey': this.selectedKey ?? '',
        'location': this.addAddressForm.value.location,
        'localityArea': this.addAddressForm.value.locality ?? '',
        'blockNo': this.addAddressForm.value.blockNo ?? null,
        'createdAt': firebase.default.firestore.Timestamp.now(),
        'currentGeo': this.geoPoint ? this.geoPoint : null,
        ...(this.selectedLocation && {
          'placeDetails': {
            // 'description': this.addressComponents ?? null,
            'geo': this.geoPoint?.latitude ? {
              'lat': this.geoPoint?.latitude,
              'lng': this.geoPoint?.longitude,
            } : null,
            'name': this.name ?? null,
            'addressComponents': this.addressComponents ?? null,
            'formattedAddress': this.selectedLocation,
            'placeId': this.placeId ?? null
          }
        })
      } as unknown as Address;
      if (this.tempUid) {
        await this.profileService.addAddress(this.tempUid, tempAddress);
      }
      this.dataService.changeTempAddress(tempAddress);
    }
    // this.spinnerService.closeAllModals();
    this.quickbookingServcice.closeModal();
  }

  landmarkKeySelection(key: string) {
    // console.log('landmarkKey: ', key, this.selectedKey);
  }

  setLandmarkKeys() {
    this.quickBookingService.getBookingData()
      .subscribe(async res => {
        this.booking = await res.data() as Booking;
        if (this.booking) {
          if (this.booking?.addressLandMark) {
            this.landmarkKeys = this.booking?.addressLandMark;
          }
        }
      });
  }

  async changeAddress() {
    if (this.geoPoint && this.geoPoint.latitude && this.geoPoint.longitude) {
      // const getGeoStatus = await this._getGeocodeData(this.geoPoint?.latitude, this.geoPoint?.longitude);
      if (!this.getGeoStatus) {
        this.toastr.clear()
        this.toastr.error(
          'Please select your location on map!', 'Something went wrong!',
          { positionClass: 'toast-bottom-center',toastClass:'address-toastr ngx-toastr' },
        )
        return;
      }
    }

    if (this.currentAddress) {
      if (!this.selectedLocation || !this.addAddressForm?.value?.location) {
        this.toastr.clear();
        this.toastr.error(
          'Please select your location on map!', 'Something went wrong!',
          { positionClass: 'toast-bottom-center',toastClass:'address-toastr ngx-toastr' },
        );
        return;
      }

      this.updateDisplayName();
      if (!this.hasDisplayName && this.profileForm?.invalid) return;

      if (this.addAddressForm.invalid || !this.selectedKey) {
        this.toastr.clear();
        this.toastr.error(
          'Please complete all required fields!', 'Something went wrong!',
          { positionClass: 'toast-bottom-center',toastClass:'address-toastr ngx-toastr' },
        );
        return;
      }
      this.disableSubmit = true;
      // this.spinnerService.openLoadingModal();
      if (!this.isTempAddress) {
        await this.profileService.deleteAddress(this.localUid, this.currentAddress);
        await this.profileService.addAddress(
          this.localUid,
          {
            'flatNo': this.addAddressForm.value.flatNo,
            'flatName': this.addAddressForm.value.flatName,
            'id': Date.now().toString(),
            'landmark': this.nearLandmark,
            'landmarkKey': this.selectedKey ?? '',
            'location': this.addAddressForm.value.location,
            'localityArea': this.addAddressForm.value.locality ?? '',
            'blockNo': this.addAddressForm.value.blockNo ?? null,
            'createdAt': firebase.default.firestore.Timestamp.now(),
            'currentGeo': this.geoPoint ? this.geoPoint : null,
            'placeDetails': {     // Get from google map API
              // 'description': null,
              'geo': this.geoPoint?.latitude ? {
                'lat': this.geoPoint?.latitude,
                'lng': this.geoPoint?.longitude,
              } : null,
              'name': null,
              'addressComponents': this.addressComponents ?? null,
              'formattedAddress': this.selectedLocation,
              'placeId': this.placeId ?? null
            }
          });
      } else {
        var tempAddress = {
          'flatNo': this.addAddressForm.value.flatNo,
          'flatName': this.addAddressForm.value.flatName,
          'id': Date.now().toString(),
          'isWebUser': true,
          'landmark': this.nearLandmark,
          'landmarkKey': this.selectedKey ?? '',
          'location': this.addAddressForm.value.location,
          'localityArea': this.addAddressForm.value.locality ?? '',
          'blockNo': this.addAddressForm.value.blockNo ?? null,
          'createdAt': firebase.default.firestore.Timestamp.now(),
          'currentGeo': this.geoPoint ? this.geoPoint : null,
          ...(this.selectedLocation && {
            'placeDetails': {
              // 'description': this.addressComponents ?? null,
              'geo': this.geoPoint?.latitude ? {
                'lat': this.geoPoint?.latitude,
                'lng': this.geoPoint?.longitude,
              } : null,
              'name': this.name ?? null,
              'addressComponents': this.addressComponents ?? null,
              'formattedAddress': this.selectedLocation,
              'placeId': this.placeId ?? null
            }
          })
        } as unknown as Address;
        if (this.tempUid) {
          await this.profileService.deleteAddress(this.tempUid, this.currentAddress);
          await this.profileService.addAddress(this.tempUid, tempAddress);
        }
        this.dataService.changeTempAddress(tempAddress);
      }
      // this.spinnerService.closeAllModals();
      this.quickbookingServcice.closeModal();
    }
  }

  initGeocodeData(lat: number, lng: number, isChangeEvent?: boolean, isDragEnd?: boolean) {
    if (lat && lng) {
      this.latLngStr = lat + ', ' + lng;
      this.geoPoint = new firebase.default.firestore.GeoPoint(lat, lng);
      // this.getGeocodeData(lat, lng);
      if (this.geolocationService.isValidLocation(lat, lng, this.servicePolygons)) {
        this.selectedLatLng = {
          lat: lat,
          lng: lng
        }
        this.toastr?.clear();
      } else {
        // this.selectedLatLng = this.getDefaultLatLng();
        if (this.googleMapMarker && this.selectedLatLng?.lat && !isChangeEvent) {
          this.googleMapMarker.setPosition(new google.maps.LatLng(this.selectedLatLng.lat, this.selectedLatLng.lng))
          this.mapElement.setCenter(new google.maps.LatLng(this.selectedLatLng.lat, this.selectedLatLng.lng));
        }
        // this.toastr.clear();
        if (!isDragEnd && !isChangeEvent) {
          // this.toastr.error(
          //   this.getServiceZoneErrorTxt(), 'No Serving Area',
          //   { positionClass: 'toast-bottom-center',toastClass:'address-toastr ngx-toastr' },
          // );
        }
      }
    }
  }

  _getGeocodeData(lat: number, lng: number, isConfirmLocation?: boolean) {
    const _latLngStr = lat + ', ' + lng;
    return new Promise<boolean>(resolve => {
      this.apiService.getGeocodeData((this.latLngStr || _latLngStr) ?? '')
        .subscribe(
          async response => {
            this.toastr.clear();
            if (response?.status === 'OK' && response) {
              if (this.geolocationService.isValidLocation(lat, lng, this.servicePolygons)) {
                if (this.isDisabledLocation(lat, lng, this.disabledPolygons)) return;
                this.selectedLatLng = {
                  lat: lat,
                  lng: lng
                }
                this.toastr?.clear();
                var result = response['results'][0];
                this.selectedLocation = result['formatted_address'];
                this.addAddressForm.get('location')?.setValue(this.selectedLocation);
                this.addressComponents = result['address_components'];
                this.addAddressForm.get('locality')?.setValue(this.getLocality(this.addressComponents));
                this.placeId = result['place_id'];
                this.crntAddressComponents = null;
                this.name = '';
                this.getFormatedAddress();
                resolve(true);
              } else {
                if (this.googleMapMarker && this.selectedLatLng?.lat && !isConfirmLocation) {
                  this.googleMapMarker.setPosition(new google.maps.LatLng(this.selectedLatLng.lat, this.selectedLatLng.lng))
                  this.mapElement.setCenter(new google.maps.LatLng(this.selectedLatLng.lat, this.selectedLatLng.lng));
                }
                this.toastr.error(
                  this.getServiceZoneErrorTxt(), 'No Serving Area',
                  { positionClass: 'toast-bottom-center',toastClass:'address-toastr ngx-toastr' },
                );
                resolve(false);
              }
            } else {
              this.toastr.error(
                'Something went wrong!', 'Something went wrong!',
                { positionClass: 'toast-bottom-center',toastClass:'address-toastr ngx-toastr' },
              );
              resolve(false);
            }
          },
          error => {
            this.toastr.clear();
            this.toastr.error(
              'Something went wrong!', 'Something went wrong!',
              { positionClass: 'toast-bottom-center',toastClass:'address-toastr ngx-toastr' },
            );
            resolve(false);
            throw error;
          }
        )
    })
  }

  // toggleAdditionalInfo() {
  //   this.showAdditionalDetails = !this.showAdditionalDetails;
  // }

  geoLocate() {
    this.isLocating = true;
    if (navigator.geolocation) {
      this.geolocationService.getPosition().then(async pos => {
        // console.log(`Positon: ${pos?.lat},${pos?.lng}`)
        this.initialMapCoordinates = {
          lat: pos.lat,
          lng: pos.lng
        }
        this.mapConfigs.center = this.initialMapCoordinates
        this.mapConfigs.marker = this.initialMapCoordinates
        this.initMap(pos.lat, pos.lng)
        this.initGeocodeData(pos.lat, pos.lng)
        await this._getGeocodeData(pos.lat, pos.lng, true);
        this.isLocating = false;
      }).catch((e) => {
        console.log(`Positon Error: ${e}`);
        this.toastr.clear();
        this.toastr.error(
          'Unable to get your location!', 'Something went wrong!',
          { positionClass: 'toast-bottom-center',toastClass:'address-toastr ngx-toastr' },
        );
        this.isLocating = false;
      })
    } else {
      this.toastr.clear();
      this.toastr.error(
        'Your browser doesn\'t support geolocation!', 'Something went wrong!',
        { positionClass: 'toast-bottom-center',toastClass:'address-toastr ngx-toastr' },
      );
      this.isLocating = false;
    }
  }

  // openFullScreen() {
  //   if (window.innerWidth > 575) return;
  //   if (!document?.fullscreenElement) {
  //     // document?.documentElement?.requestFullscreen();
  //     let modal = $('.new-address-modal').get(0);
  //     if (modal.requestFullScreen) {
  //       modal.requestFullScreen();
  //     } else if (modal.webkitRequestFullScreen) {
  //       modal.webkitRequestFullScreen();
  //     } else if (modal.mozRequestFullScreen) {
  //       modal.mozRequestFullScreen();
  //     } else if (modal.msRequestFullscreen) {
  //       modal.msRequestFullscreen();
  //     } else if (modal.webkitEnterFullscreen) {
  //       modal.webkitEnterFullscreen(); //for iphone this code worked
  //     }
  //   }
  // }

  // closeFullScreen() {
  //   if (document.fullscreenElement && document.exitFullscreen)
  //     document.exitFullscreen();
  // }

  getLocality(addressComponents: any[]): string {
    let locality = this.getLocalityStr(addressComponents);
    if (!locality && this.placeAddressData?.addressComponents) {
      locality = this.getLocalityStr(this.placeAddressData?.addressComponents);
    }
    return locality || 'Dubai';
  }

  getLocalityStr(addressComponents: any[]): string {
    const componentsLength = addressComponents.length;
    var localityStr = '';
    for (let i = 0; i < componentsLength; i++) {
      for (let j = 0; j < addressComponents[i]?.types?.length; j++) {
        const type = addressComponents[i]?.types[j];
        const component = addressComponents[i];
        localityStr = component?.long_name ?? component?.short_name ?? '';
        if ((type === 'neighborhood' || type === 'sublocality' || type === 'locality') && this.containsEnglishWords(localityStr)) {
          return localityStr;
        }
      }
    }
    return '';
  }

  async confirmLocation() {
    if (!this.geoPoint?.latitude || (this.geoPoint?.latitude === this.defaultLatLng?.lat && this.geoPoint?.longitude === this.defaultLatLng?.lng)) {
      this.toastr.clear();
      // 'Please select your location on map or search for your location!'
      this.toastr.error(
        'Sorry, we are currently not offering services in this area!', 'No Serving Area',
        { positionClass: 'toast-bottom-center',toastClass:'address-toastr ngx-toastr' },
      )
      return;
    }
    this.getGeoStatus = undefined;
    this.getGeoStatus = await this._getGeocodeData(this.geoPoint?.latitude, this.geoPoint?.longitude, true);
    if (!this.getGeoStatus) {
      // this.toastr.clear()
      this.toastr.error(
        this.getConfirmLocErrorTxt(), 'Something went wrong!',
        { positionClass: 'toast-bottom-center',toastClass:'address-toastr ngx-toastr' },
      )
      return;
    } else {
      if (this.isConfirmationOnly) {
        this.setGuestAddress();
      } else {
        this.showMapScreen = false;
      }
    }
  }

  goBack() {
    this.showMapScreen = true;
  }

  getFormatedAddress(): string {
    const addressComponents = this.addressComponents;
    if (addressComponents === this.crntAddressComponents) {
      return this.crntLocStr;
    }
    this.crntAddressComponents = addressComponents;
    const componentsLength = addressComponents?.length;
    const placeName = this.name ? (this.name + ' - ') : '';
    const location = this.addAddressForm?.value?.location ?? '';
    var plusCode = '';
    for (let i = 0; i < componentsLength; i++) {
      for (let j = 0; j < addressComponents[i]?.types?.length; j++) {
        const type = addressComponents[i]?.types[j];
        if (type === 'plus_code') {
          const component = addressComponents[i];
          plusCode = component?.long_name ?? component?.short_name ?? '';
        }
        if (plusCode) {
          var formattedLoc = null;
          formattedLoc = location.replace((plusCode + ' - '), '');
          formattedLoc = formattedLoc.replace(plusCode, '');
          this.crntLocStr = placeName + formattedLoc ?? location ?? placeName;
          return placeName + formattedLoc ?? location ?? placeName;
        }
      }
    }
    this.crntLocStr = placeName + (this.selectedLocation ?? '');
    return placeName + (this.selectedLocation ?? '');
  }

  get fval() {
    return this.profileForm.controls;
  }

  async updateDisplayName() {
    this.submitted = true;
    if (this.hasDisplayName || this.profileForm?.invalid) return;
    let data = {};
    data = {
      displayName: this.profileForm?.value?.displayName ?? '',
    };
    await this.profileService.updateProfileInfo(this.localUid, data);
    this.dataService.changeProfileName(this.profileForm?.value?.displayName);
  }

  setGuestAddress() {
    const formattedAddress = this.getFormatedAddress();
    const landmarkKey = this.getGuestLandmarkKey();
    const _address = {
      id: 'GUEST01',
      currentGeo: this.geoPoint,
      landmarkKey: landmarkKey ?? 'Motor City',
      location: formattedAddress ?? 'Dubai, UAE',
      localityArea: this.getLocality(this.addressComponents) ?? ''
    } as Address;
    this.dataService.changeGuestAddress(_address);
    this.closeModal();
  }

  setGuestAddressInfo() {
    const guestAddress = this.dataService.getGuestAddress();
    if (!guestAddress?.id) return;
    this.crntGeo = { lat: guestAddress?.currentGeo?.latitude, lng: guestAddress?.currentGeo?.longitude }
    this.selectedLocation = guestAddress.location ?? ''
    this.addButtonText = 'Save Changes'
    this.addAddressForm.setValue({
      location: guestAddress.location ?? '',
      flatNo: guestAddress.flatNo ?? '',
      flatName: guestAddress.flatName ?? '',
      locality: guestAddress.localityArea ?? '',
      blockNo: guestAddress.blockNo ?? ''
    });
    this.selectedKey = 'Home';
    if (this.crntGeo.lat && this.crntGeo.lng)
      this.isLocating = false;
  }

  getGuestLandmarkKey(): string {
    // const addressComponents = this.addressComponents;
    // const componentsLength = addressComponents?.length;
    // let localityStr = '';
    // for (let i = 0; i < componentsLength; i++) {
    //   for (let j = 0; j < addressComponents[i]?.types?.length; j++) {
    //     const type = addressComponents[i]?.types[j];
    //     const component = addressComponents[i];
    //     localityStr = component?.long_name ?? component?.short_name ?? '';
    //     if ((type === 'neighborhood' || type === 'sublocality' || type === 'locality') && this.containsEnglishWords(localityStr)) {
    //       return localityStr;
    //     }
    //   }
    // }
    const localityStr = this.getLocalityStr(this.addressComponents);
    return localityStr ?? 'Dubai';
  }

  skipMapScreen(address: Address) {
    if (address?.id === 'GUEST01' && this.geolocationService.isValidLocation(address?.currentGeo?.latitude, address?.currentGeo?.longitude, this.servicePolygons)) {
      this.showMapScreen = false;
      this.geoPoint = address?.currentGeo;
      this.confirmLocation();
    }
  }

  /* removeArabicText(address: string): string {
    const arabicPattern = /[\u0600-\u06FF\s]/g;
    const res = address.replace(arabicPattern, '');
    return res;
  } */

  containsEnglishWords(address: string): boolean {
    const englishPattern = /[a-zA-Z]/;
    const res = englishPattern.test(address);
    return res;
  }

  setServicePolygons(data: any[]) {
    const crntUrl = this.router.url;
    if (crntUrl === '/addresses/view')
      this.servicePolygons = [];
    else
      this.servicePolygons = data;
  }

  setDisabledPolygons() {
    const crntUrl = this.router.url;
    if (crntUrl === '/addresses/view')
      this.disabledPolygons = [];
    else {
      let disabledPolygons = this.dataService.getDisabledPolygons();
      this.disabledPolygons = disabledPolygons ?? [];
    }
  }

  setCategoryData() {
    const crntUrl = this.router.url;
    if (crntUrl === '/addresses/view')
      this.subCategoryData = false;
    else
      this.subCategoryData = this.dataService.getMaidServiceInfo();
  }

  getServiceZoneErrorTxt() {
    const categoryType = this.subCategoryData?.subCategory?.newType;
    if (categoryType === 22)
      return 'Sorry, we are currently not offering zap service in this area';
    return 'Sorry we are currently offering our service only in Dubai';
  }

  getConfirmLocErrorTxt() {
    const categoryType = this.subCategoryData?.subCategory?.newType;
    if (categoryType === 22)
      return 'Sorry, we are currently not offering zap service in this area';
    return 'Please select your location on map!';
  }

  drawZonePolygons(servicePolygons: any[], map: google.maps.Map<Element>, isDisabled: boolean) {
    let polygonList: any[] = [];
    if (servicePolygons && servicePolygons?.length > 0) {
      servicePolygons.forEach((e) => {
        for (var poly in e) {
          polygonList.push(e[poly]);
        }
      })
    }
    if (polygonList?.length > 0 && map) {
      polygonList.forEach((polygonCoords) => {
        const zone = new google.maps.Polygon({
          paths: polygonCoords,
          strokeColor: isDisabled ? "#ff6347" : "#114CED",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: isDisabled ? "#ff6347" : "#114CED",
          fillOpacity: 0.2,
        });
        zone.setMap(map);
      });
    }
  }

  getDefaultLatLng() {
    const categoryType = this.subCategoryData?.subCategory?.newType;
    // if (categoryType === 22)
    //   return { lat: 25.06015605, lng: 55.208986969134145 };
    return { lat: 25.042853, lng: 55.241818 };
  }

  isDisabledLocation(lat: number, lng: number, disabledPolys: any): boolean {
    if (!disabledPolys || disabledPolys?.length<1 || disabledPolys===undefined) return false;
    if (this.router.url === '/addresses/view' ? !this.geolocationService.isDisabledLocation(lat, lng, disabledPolys) : this.geolocationService.isDisabledLocation(lat, lng, disabledPolys)) {
      const errorTxt = 'Sorry, we are currently not offering services in this area.';
      this.toastr.clear();
      this.toastr.error(
        errorTxt, 'Something went wrong!',
        { positionClass: 'toast-bottom-center',toastClass:'address-toastr ngx-toastr' },
      );
      return true;
    }
    return false;
  }

  clearSearchInput() {
    const el = document.getElementById('mapSearchField') as HTMLInputElement;
    if (el) {
      el.value = '';
      el?.focus();
    }
    this.isSearchable = true;
  }

  public search() {
    this.isSearchable = true;
    this.modalTitle = 'Search Your Location';
  }

  public closeSearchModal() {
    this.isSearchable = false;
    this.modalTitle = "Your Address";
  }

  public openSkipModal() {
    const modalRef = this.modalService.open(SkipLocationComponent, {
      backdrop: true,
      centered: true,
      keyboard: false,
      scrollable: true,
      size:'sm'
    });
    modalRef.componentInstance.onAction.subscribe((action: any) => {
      modalRef.close();
      if (action === 'add') {

      } else if (action === 'skip') {
        this.quickbookingServcice.closeModal();
        this.router.navigate(['/dubai']);
      }
    });
  }
}


