<div class="whats-include-modal">
    <div class="modal-body container-fluid">
        <div class="scroll-sec">
            <img src="assets/images/icons/close-sm.png" alt="close.png" (click)="activeModal.close()">
            <div class="info mt-1">
                <ng-container *ngFor="let d of data; let i=index;">
                    <label *ngIf="d?.main" class="mb-2">{{getTranslatedText(d.heading)}}</label>
                    <label *ngIf="!d?.main && d?.isTitle" class="sub-title">{{getTranslatedText(d.heading)}}</label>
                    <div *ngIf="!d.isList">
                        <p *ngIf="d?.isTitle">{{getTranslatedText(d.description)}}</p>
                        <p *ngIf="!d?.isTitle"><b>{{getTranslatedText(d.heading)}}</b> - {{getTranslatedText(d.description)}}</p>
                    </div>
                    <ul *ngIf="d.isList">
                        <li *ngFor="let item of d.items">{{getTranslatedText(item)}}</li>
                    </ul>
                </ng-container>
            </div>
        </div>
    </div>
</div>