import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MonthlyFrequencyPriceDetails, MonthlyPackage, MonthlyPackageDetails, MonthlyPackageFrequency } from 'src/app/models/subCategory.model';
import { DomainService } from 'src/app/services/domain.service';
import { RemoveAlertComponent } from './remove-alert/remove-alert.component';

@Component({
  selector: 'app-package-booking',
  templateUrl: './package-booking.component.html',
  styleUrls: ['./package-booking.component.scss'],
})
export class PackageBookingComponent implements OnInit {

  @Input() data!: MonthlyPackageDetails;
  @Input() maidServiceInfo!: any;
  @Input() otherInfo: any;
  @Input() action: string = '';
  @Input() editData: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  public  isArabic: boolean = false;
  public isActive: boolean = false;
  public selectedPackage!: MonthlyPackage;
  public selectedFrequency!: MonthlyPackageFrequency;
  public packageId: string = "";
  public frequencyId: string = "";
  public isPackageChanged: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private domainService: DomainService
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.isArabic = this.otherInfo?.isArabic;
      console.log(this.data?.frequencies)
      this.isActive = this.data.isActive;
      this.setModalData(this.maidServiceInfo?.maidServiceData?.monthlyPackageDetails);
    }
  }

  setModalData(packageDetails?: any) {
    if (packageDetails) {
      this.selectedPackage = packageDetails.monthlyPackage;
      this.packageId = this.selectedPackage.itemId;
      this.selectedFrequency = packageDetails.frequency;
      this.frequencyId = this.selectedFrequency.itemId;
    } else {
      const prefferedPackageDetails = this.getPreferredMonthlyPackageData(this.data);
      const defaultPackageDetails = this.getDefaultMonthlyPackageData(this.data);
      if (prefferedPackageDetails) {
        this.selectedPackage = prefferedPackageDetails[0]!;
        this.selectedFrequency = prefferedPackageDetails[1]!;
        this.packageId = this.selectedPackage.itemId;
        this.frequencyId = this.selectedFrequency.itemId;
      } else if (defaultPackageDetails) {
        this.selectedPackage = defaultPackageDetails[0]!;
        this.selectedFrequency = defaultPackageDetails[1]!;
        this.packageId = this.selectedPackage.itemId;
        this.frequencyId = this.selectedFrequency.itemId;
      }
    }
  }

  onPackageSelection(monthlyPackage: MonthlyPackage) {
    const defaultPackageDetails = this.getDefaultMonthlyPackageData(this.data);
    if (defaultPackageDetails) {
      this.isPackageChanged = true;
      const pkg = defaultPackageDetails[0]!;
      this.packageId = pkg.itemId;
      this.selectedPackage = pkg;
      const freq = defaultPackageDetails[1]!;
      this.frequencyId = freq.itemId;
      this.selectedFrequency = freq;
    }
  }

  private getDefaultMonthlyPackageData(packageDetails: MonthlyPackageDetails): [MonthlyPackage | null, MonthlyPackageFrequency | null] {
    const preferredPackage = packageDetails?.packages.find(p => p.itemId === packageDetails.preferredDetails?.packageId) ?? null;
    const preferredFrequency = packageDetails?.frequencies.find(f => f.itemId === packageDetails.preferredDetails?.frequencyId) ?? null;
    return [preferredPackage, preferredFrequency];
  }

  private getPreferredMonthlyPackageData(packageDetails: MonthlyPackageDetails): [MonthlyPackage | null, MonthlyPackageFrequency | null] {
    const preferredPackage = packageDetails?.packages.find(p => p.isPreferred === true) ?? null;
    const preferredFrequency = packageDetails?.frequencies.find(f => f.isPreferred === true) ?? null;
    return [preferredPackage, preferredFrequency];
  }

  public getFrequencySubText(pkg?: MonthlyPackage, frequency?: MonthlyPackageFrequency): string {
    if (!pkg || !frequency) return '';
    const count = pkg.weekCount ?? 0;
    const visitsPerWeek = frequency.visitsPerWeek ?? 0;
    const hour: string = this.otherInfo?.hours;
    return frequency.subTitle?.replace('{hour}', hour).replace('{count}', `${count * visitsPerWeek}`) ?? '-';
  }

  public getFrequencyItemPrice(pkg?: MonthlyPackage, frequency?: MonthlyPackageFrequency): number {
    return this.addZeros(this.domainService.getFrequencyItemPrice(pkg, frequency, this.getFrequencyPriceDetails(frequency?.itemId!), this.otherInfo?.unitPrice ?? 0, this.otherInfo?.addOnPrice ?? 0))
  }

  public addZeros(value: number): any {
    return value.toLocaleString("en", { useGrouping: false, minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  public disableMonthlyBooking(event: any) {
    const modalRef = this.modalService.open(RemoveAlertComponent, {
      backdrop: 'static',
      centered: true,
      keyboard: false,
      scrollable: true
    });
    modalRef.componentInstance.text = 'Are you sure want to cancel monthly package booking?';
    modalRef.result.then((res) => {
      if (res) {
        if (res === 'REMOVE' && this.selectedPackage?.itemId) {
          this.isActive = false;
          let maidServiceInfo = this.maidServiceInfo;
          const total: number = this.otherInfo.unitPrice + this.otherInfo?.addOnPrice;
          delete maidServiceInfo?.maidServiceData['monthlyPackageDetails'];
          maidServiceInfo = {
            ...maidServiceInfo,
            maidServiceData: {
              ...maidServiceInfo?.maidServiceData,
              totalAmountVal: total,
              totalWithVat: this.addVatPercentage(total),
            },
          }
          this.activeModal.close(maidServiceInfo);
        }
      }
    });
    return false;
  }

  addVatPercentage(amount: number) {
    const vatPercentage: number = this.otherInfo?.vatPercentage ?? 0;
    if (vatPercentage > 0) {
      const val = (amount / 100) * (100 + vatPercentage);
      return val;
    } else {
      return amount;
    }
  }

  public onFrequencySelection() {
    if (this.maidServiceInfo?.maidServiceData?.monthlyPackageDetails) {
      this.confirm();
    }
  }

  public confirm() {
    let maidServiceInfo = this.maidServiceInfo;
    let pkg = this.data.packages?.find(p => p.itemId === this.packageId);
    let frequency = this.data.frequencies?.find(f => f.itemId === this.frequencyId);
    if (pkg && frequency) {
      const count: number = pkg?.weekCount;
      const visitsPerWeek: number = frequency?.visitsPerWeek;
      frequency.description = `${pkg?.packageName} - Total ${count * visitsPerWeek} jobs`;
    }
    const monthlyPackage = maidServiceInfo?.maidServiceData?.monthlyPackageDetails;
    const priceDetails: MonthlyFrequencyPriceDetails = this.getFrequencyPriceDetails(frequency?.itemId!);
    let monthlyPackageDetails = {
      monthlyPackage: pkg,
      frequency: frequency,
      paymentDetails: this.data?.paymentDetails,
      schedule: monthlyPackage?.schedule,
      startingDate: monthlyPackage?.startingDate,
      endingDate: monthlyPackage?.endingDate,
      customJobStartDate: monthlyPackage?.startingDate,
      priceDetails: priceDetails,
      preferredDetails: this.data?.preferredDetails
    }
    maidServiceInfo = {
      ...maidServiceInfo,
      maidServiceData: {
        ...maidServiceInfo?.maidServiceData,
        totalAmountVal: this.otherInfo.secondarySelectionSum + this.otherInfo?.addonTotal ?? 0,
        monthlyPackageDetails: monthlyPackageDetails
      },
    }
    this.activeModal.close(maidServiceInfo);
  }

  public close() {
    this.activeModal.close();
  }

  public getFrequencyPriceDetails(freqId: string): any {
    return this.domainService.getFrequencyPriceDetails(this.data?.priceDetails, this.otherInfo?.itemId, freqId);
  }


  public getTranslatedText = (text: string | any) => this.domainService.getTranslatedText(text);
  public getTranslatedAmount = (text: string | any) => this.domainService.getTranslatedAmount(text);

}
