<div class="tnc-modal">
    <div class="tnc-modal__header d-flex align-items-center justify-content-between" ngbAutofocus>
        <h6>{{getTranslatedText(title ?? 'Terms & Conditions')}}</h6>
        <a (click)="close()"><img src="assets/images/icons/close-brwn.svg"></a>
    </div>

    <div class="modal-body container-fluid">
        <ng-container *ngIf="content==''">
            <ul *ngIf="type === 0">
                <li *ngFor="let e of tnc">{{getTranslatedText(e)}}</li>
            </ul>
            <div *ngIf="type === 1" class="para">
                <p *ngFor="let e of tnc">{{getTranslatedText(e)}}</p>
            </div>
        </ng-container>
        <ng-container *ngIf="content">
            <div [innerHTML]="content"></div>
        </ng-container>
    </div>
</div>